import logo from './logo.svg';
import './App.css';

import { getDatabase, ref, get, set, onValue, push } from '@firebase/database'
import { initializeApp } from '@firebase/app'


const firebaseConfig = {

  apiKey: "AIzaSyB-l1ZVTB2xX7b2db4l9o92iNNa3AYTVtQ",

  authDomain: "hptu-plus.firebaseapp.com",

  databaseURL: "https://hptu-plus-default-rtdb.firebaseio.com",

  projectId: "hptu-plus",

  storageBucket: "hptu-plus.appspot.com",

  messagingSenderId: "209940602863",

  appId: "1:209940602863:web:f6db860f36dbadc5db601a",

  measurementId: "G-HH3YP4DWRF",

  databaseURL: "https://hptu-plus-default-rtdb.firebaseio.com/"

};





function App() {

  return (
    <div className="App">

      <button onClick={() => { updateData() }} >
        Update Progress
      </button>


      <span id='list'></span>




    </div>
  );
}

export default App;











async function updateData() {


  var lastId;


  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const dbGetData = ref(database, "result");


  //Get Last ID

  await onValue(dbGetData, (snapshot) => {
    const data = snapshot.val();

    const arrayData = Object.values(data)

    lastId = arrayData[arrayData.length - 1].id



  })



  // Get new ID title

  var newId = parseInt(lastId) + 1

  console.log(newId)

  var myDate = new Date().toLocaleDateString();

  var pageTitle;


  if (newId !== null && newId.length !== 0) {
    const url = 'https://get-html-from-url.deepanshuchoudhary17022000.workers.dev?url=http://results.indiaresults.com/hp/himtu/result3/mquery.aspx?id=' + newId.toString();

    const response = await fetch(url);

    pageTitle = await response.text();
  }









  // Send Notification

  let sendReport = "0"

  if (newId !== null && pageTitle !== "0" && pageTitle !== 0) {

    const sendUrl = `https://fcm-hptu-plus.deepanshuchoudhary17022000.workers.dev/?title=${encodeURIComponent(pageTitle)}&body=${encodeURIComponent(pageTitle)}`;

    const sendResponse = await fetch(sendUrl);

    sendReport = await sendResponse.text();

    alert("notification send for " + newId);

  }







  if (sendReport === "0" && newId === null && newId.length > 0) {
    
  } else {
    alert("Notification send for : " + newId);

    // Saving data to db

    if (pageTitle === "0") {
      alert("no new Result")
    } else {
      let dbSetData = ref(database, "result/m" + newId)

      set(dbSetData,
        {
          "id": newId.toString(),
          "date": myDate.toString(),
          "title": pageTitle
        }

      );

      ShowDB();

    }

  }

}







function ShowDB() {

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const dbRef = ref(database, "result");



  onValue(dbRef, async (snapshot) => {
    const data = snapshot.val();

    const arrayData = Object.values(data)

    var div = document.getElementById('list');

      var li = document.createElement('li');

      li.textContent = arrayData[arrayData.length - 1].title + "  " + arrayData[arrayData.length - 1].id

      

      div.append(li)


      
    

  });




}
